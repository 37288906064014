#tabs {
  border: none; }
  #tabs #range_slider_price {
    margin: 50px 0px; }
  #tabs #range_slider_price .ui-slider-handle {
    width: 1.8em;
    height: 1.8em;
    top: -.6em;
    margin-left: -.9em; }
  #tabs #range_slider_price .ui-slider-handle .tip {
    position: absolute;
    width: 5em;
    height: 1.8em;
    background-color: white;
    color: gray;
    border: 1px solid gray;
    padding: 2px 0px;
    font-size: 1em;
    text-align: center;
    overflow: hidden; }
  #tabs #range_slider_price .ui-slider-handle:nth-of-type(1) .tip {
    top: 2.4em; }
  #tabs #range_slider_price .ui-slider-handle:nth-of-type(2) .tip {
    bottom: 2.4em;
    right: 0px; }
  #tabs #range_slider_price .ui-slider-range {
    background: gray; }
  #tabs #item_listing {
    /*
		border-bottom: none;
		margin-bottom: 0px;
		padding: 0px;
*/ }
  #tabs #orderby_select {
    /*
		margin: 0px;
		border-radius: 4px;
*/ }
  #tabs #filter_brand_input {
    width: 100%;
    /*
		margin: 0px;
		margin-bottom: 5px;
		font-size: 20px;
		border-radius: 4px;
		background-color: white;
*/
    border: 1px solid lightgray; }
  #tabs #filter_brand_content {
    max-height: 200px;
    overflow: auto; }
  #tabs #doyoumean {
    margin: 10px 0px; }
  #tabs .ui-tabs-nav {
    /*
		background: transparent;
		padding: 0px;
		border: 1px solid #bcbbbb;
		border-radius: 4px;
*/
    width: max-content; }
    #tabs .ui-tabs-nav .ui-tabs-tab {
      border: none;
      margin: 0px;
      border-radius: 0px; }
    #tabs .ui-tabs-nav .ui-tabs-nav .ui-tabs-tab.ui-state-active {
      margin: 0px;
      padding: 0px; }
    #tabs .ui-tabs-nav .ui-tabs-nav .ui-tabs-tab.ui-state-default {
      background-color: white; }
    #tabs .ui-tabs-nav .ui-tabs-nav .ui-tabs-tab:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    #tabs .ui-tabs-nav .ui-tabs-nav .ui-tabs-tab:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }
  #tabs .ui-tabs-panel {
    /*
		border: 1px solid #bcbbbb;
		margin-top: 5px;
		border-radius: 4px;
*/ }
  #tabs .ui-state-active {
    background-color: gray; }
  #tabs .filter_icon {
    width: 45%;
    height: 45%; }
  #tabs .tag_icon {
    width: 12%;
    height: 12%; }
  #tabs .doyoumean_headline {
    /*
		font-size: 18px;
*/
    color: #dd4b39; }
  #tabs .doyoumean_value {
    /*
		font-size: 18px;
*/
    color: #1a0dab; }
  #tabs .doyoumean_value:hover {
    text-decoration: underline; }
  #tabs .category_line {
    /*
		padding-top: 5px;
		padding-bottom: 5px;
*/ }
    #tabs .category_line .category_line_left {
      float: left;
      width: 70%; }
    #tabs .category_line .category_line_right {
      float: right;
      width: 30%; }
  #tabs .information_item {
    /*
		margin-bottom: 35px;
*/ }
    #tabs .information_item .headline {
      margin-bottom: 0px; }
      #tabs .information_item .headline a {
        /*
				font-size: 18px;
*/
        color: #1a0dab; }
      #tabs .information_item .headline a:visited {
        color: #609; }
    #tabs .information_item .url {
      color: green; }
    #tabs .information_item .keywords {
      margin-top: 10px; }
    #tabs .information_item .description {
      color: gray; }
    #tabs .information_item .keywords .keyword_container {
      margin-bottom: 5px; }
    #tabs .information_item .hit {
      color: gray; }
    #tabs .information_item .keywords .keyword_container .keyword {
      color: #1a0dab;
      margin-right: 2px;
      /*
				font-size: 15px;
*/ }
      #tabs .information_item .keywords .keyword_container .keyword .icon {
        margin-right: 2px; }
    #tabs .information_item .keywords .keyword_container .keyword:visited {
      color: #609; }
