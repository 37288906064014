#search_input,
[data-ez-search-input] {
  /*
	margin: 0px;
	margin-top: 20px;
	font-size: 20px;
	border: none;
	border-radius: 4px;
	background-color: white;
*/ }

#search_output,
[data-ez-search-output] {
  position: absolute;
  min-width: 500px;
  background-color: white;
  margin: 0px;
  margin-top: 20px;
  z-index: 100;
  /*
	padding: 10px;
	border-radius: 4px;
*/
  -webkit-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
  -moz-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
  box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56); }
  #search_output ul,
  [data-ez-search-output] ul {
    margin: 0px; }
    #search_output ul li,
    [data-ez-search-output] ul li {
      list-style: none;
      /*
			padding: 5px;
*/ }
      #search_output ul li :not(:last-child),
      [data-ez-search-output] ul li :not(:last-child) {
        margin-bottom: 5px; }
    #search_output ul li.search_item,
    [data-ez-search-output] ul li.search_item {
      /*
			font-size: 16px;
			border-radius: 4px;
*/ }
      #search_output ul li.search_item .row a img,
      [data-ez-search-output] ul li.search_item .row a img {
        max-height: 100px; }
      #search_output ul li.search_item .to_shopping_cart_button,
      [data-ez-search-output] ul li.search_item .to_shopping_cart_button {
        /*
				padding: 5px 20px;
				border-radius: 5px;
				font-size: 20px;
				margin-top: 30px;
*/ }
    #search_output ul li.search_headline,
    [data-ez-search-output] ul li.search_headline {
      /*
			font-size: 22px;
*/
      border-bottom: 1px solid lightgray; }
    #search_output ul li[data-ez-search-item-active="true"],
    [data-ez-search-output] ul li[data-ez-search-item-active="true"] {
      background-color: lightgray; }

#search_output::before,
[data-ez-search-output]:before {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  bottom: 100%;
  left: 20px;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent white transparent; }

.information_item .headline .rating {
  /*
			color: #717070;
			font-style: italic;
*/ }

@media screen and (max-width: 40em) {
  #search_input,
  [data-ez-search-input] {
    margin-top: 12px; }
  #search_output,
  [data-ez-search-output] {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    margin-top: 50px;
    padding: 0px;
    min-width: inherit;
    overflow-y: auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  #search_output:before,
  [data-ez-search-output]:before {
    border: none; } }
